import { Box, Button, Grid } from "@mui/material";
import Sidenav from "../layouts/Sidenav";
import Topbar from "../layouts/Topbar";
import "../Styles/pages/user_management.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
// import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import Pagination from '@mui/material/Pagination';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import React, { useState, useEffect } from "react";
import axios from "axios";
import FormHelperText from '@mui/material/FormHelperText';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Products() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);

  };
  const handleClose = () => {
    setOpen(false);
    setproductName("");
    setproductDescription("");
    setProductCategory("");
    setproductCode("");
    setsize("");
    setline("");
    setEditingProduct(null);


    //validation
    setProductCategoryError("");
    setLineError("");
    setproductNameError("");
    setproductDescriptionError("");
    setproductCodeError("")
    setsizeError("")
  };


  const [products, setproducts] = useState([]);
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10); // Number of users to display per page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentproducts = products.slice(indexOfFirstUser, indexOfLastUser);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //get products
  useEffect(() => {
    getproducts();
  }, []);

  const getproducts = async () => {
    try {
      const response = await axios.get('https://backend-epr.vibhotech.in/api/v1/products');
      setproducts(response.data.products);
      console.log(response.data.products)
    } catch (error) {
      console.error('Error fetching products:', error.message);
    }
  };

  //add products
  const [editingProduct, setEditingProduct] = useState(null);
  const [productName, setproductName] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productDescription, setproductDescription] = useState("");
  const [productCode, setproductCode] = useState("");
  const [size, setsize] = useState("");
  const [line, setline] = useState("");


  //validation 
  const [productCategoryError, setProductCategoryError] = useState("");
  const [lineError, setLineError] = useState("");
  const [productNameError, setproductNameError] = useState("")
  const [productDescriptionError, setproductDescriptionError] = useState("");
  const [productCodeError, setproductCodeError] = useState("");
  const [sizeError, setsizeError] = useState("");

  //formvalidation
  const validateForm = () => {
    let isValid = true;

    // product name validation
    if (productName.trim() === '') {
      setproductNameError("Please select a product name");
      isValid = false;
    }

    // Validate productCategory
    if (!productCategory) {
      setProductCategoryError("Please select a product category");
      isValid = false;
    } else {
      setProductCategoryError("");
    }

    // product description validation
    if (productDescription.trim() === '') {
      setproductDescriptionError('Please enter product description');
      isValid = false;
    }

    //  product code validation
    const productCodeRegex = /^\d+$/; // Regex to match only numbers
    if (productCode === '') {
      setproductCodeError('Please enter product code');
      isValid = false;
    } else if (!productCodeRegex.test(productCode.toString().trim())) {
      setproductCodeError('Product code must contain only numbers');
      isValid = false;
    }

    // product size validation
    const sizeRegex = /^\d+$/; // Regex to match only numbers
    if (size === '') {
      setsizeError('Please enter product size');
      isValid = false;
    } else if (!sizeRegex.test(size.toString().trim())) {
      setsizeError('product size must contain only numbers');
      isValid = false;
    }

    // line validation
    if (!line) {
      setLineError("Please select product line");
      isValid = false;
    } else {
      setLineError("");
    }

    return isValid;

  };

  const addproduct = async () => {

    if (!validateForm()) {
      // If the form is not valid, don't proceed with the registration
      return;
    }

    try {
      const response = await axios.post("https://backend-epr.vibhotech.in/api/v1/products", {
        productName,
        productCategory,
        productDescription,
        productCode,
        size,
        line
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log(response.data);
      //clear input fileds
      setproductName("");
      setproductDescription("");
      setProductCategory("");
      setproductCode("");
      setsize("");
      setline("");
      getproducts();
      handleClose();
    } catch (error) {
      console.error('Error adding product:', error.message);
      toast.error("Error adding products")

    }
  }

  //get the productcategories
  const [productCategories, setProductCategories] = useState([]);

  useEffect(() => {
    getProductCategories();
  }, []);

  const getProductCategories = async () => {
    try {
      const response = await axios.get('https://backend-epr.vibhotech.in/api/v1/productCategories');
      setProductCategories(response.data.productCategories);
      console.log(response.data.productCategories)
    } catch (error) {
      console.error('Error fetching product categories:', error.message);
    }
  };

  // API call for updating product
  const handleEditClick = (product) => {
    setEditingProduct(product);
    console.log(product._id)
    // Pre-fill the input fields with the existing data
    setproductName(product.productName || "");
    setProductCategory(product.productCategory?._id || "");
    setproductDescription(product.productDescription || "");
    setproductCode(product.productCode || "");
    setsize(product.size || "");
    setline(product.line || "");
    //modal open
    setOpen(true);
  };


  const updateProduct = async () => {
    if (!validateForm()) {
      // If the form is not valid, don't proceed with the registration
      return;
    }
    try {
      const response = await axios.patch(
        `https://backend-epr.vibhotech.in/api/v1/products/${editingProduct._id}`,
        {
          productName,
          productCategory,
          productDescription,
          productCode,
          size,
          line,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data);
      setEditingProduct("")
      setproductName("");
      setproductDescription("");
      setProductCategory("");
      setproductCode("");
      setsize("");
      setline("");
      getproducts();
      handleClose();

      getproducts();
    } catch (error) {
      console.error("Error updating product:", error.message);
      toast.error("Error updating products")
    }
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [productIdToDelete, setproductIdToDelete] = useState(null);

  const handleDeleteClick = (productId) => {
    setproductIdToDelete(productId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setproductIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(
        `https://backend-epr.vibhotech.in/api/v1/products/${productIdToDelete}`
      );
      // Refresh the substrate list after deletion
      getproducts();
      console.log(`Product with ID ${productIdToDelete} deleted successfully.`);
    } catch (error) {
      console.error(
        `Error deleting Product with ID ${productIdToDelete}:`,
        error.message
      );
      toast.error("Error deleting products")
    } finally {
      handleCloseDialog();
    }
  };

  return (
    <div>
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {editingProduct ? "Update product" : "Add New product"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div className="adduser_bg">
              <div className="user_form">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Product Category </InputLabel>
                        <Select
                          labelId="product-category-label"
                          id="product-category"
                          label='Select Product Category'
                          value={productCategory}
                          onChange={(e) => {
                            setProductCategory(e.target.value);
                            setProductCategoryError("");
                          }} >
                          {productCategories.map((category) => (
                            <MenuItem key={category?._id} value={category?._id}>
                              {category?.productCategory}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error={Boolean(productCategoryError)}>
                          {productCategoryError}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Line</InputLabel>
                        <Select
                          id="product-line"
                          value={line}
                          label='Select Line'
                          onChange={(e) => {
                            setline(e.target.value);
                            setLineError("");
                          }}
                        >
                          <MenuItem value="L3">L3</MenuItem>
                          <MenuItem value="L5">L5</MenuItem>
                          <MenuItem value="L6">L6</MenuItem>
                          <MenuItem value="L8">L8</MenuItem>
                          <MenuItem value="L10">L10</MenuItem>
                          <MenuItem value="Third Party">Third Party</MenuItem>
                        </Select>
                        <FormHelperText error={Boolean(lineError)}>
                          {lineError}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="productName"
                      name="productName"
                      label="Enter Product Name"
                      variant="outlined"
                      value={productName}
                      onChange={(e) => {
                        setproductName(e.target.value);
                        setproductNameError("")
                      }}
                    />
                    <FormHelperText error={Boolean(productNameError)}>
                      {productNameError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Enter Product Code "
                      variant="outlined"
                      id="productcode"
                      value={productCode}
                      onChange={(e) => {
                        setproductCode(e.target.value);
                        setproductCodeError("")
                      }}
                    />
                    <FormHelperText error={Boolean(productCodeError)}>
                      {productCodeError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Enter Size"
                      variant="outlined"
                      id="product-size"
                      value={size}
                      onChange={(e) => {
                        setsize(e.target.value);
                        setsizeError("")
                      }}
                    />
                    <FormHelperText error={Boolean(sizeError)}>
                      {sizeError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Product Description "
                      variant="outlined"
                      id="product-des"
                      value={productDescription}
                      onChange={(e) => {
                        setproductDescription(e.target.value);
                        setproductDescriptionError("")
                      }}
                    />
                    <FormHelperText error={Boolean(productDescriptionError)}>
                      {productDescriptionError}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="cancel_save_user">
              <Button disableRipple disableElevation className="cancel" onClick={handleClose}>Cancel</Button>
              <Button disableRipple disableElevation className='save_user' onClick={editingProduct ? updateProduct : addproduct}>{editingProduct ? "Update Product" : "Save Product"}</Button>
            </div>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
      <React.Fragment>
      <BootstrapDialog
        className="sales_delete"
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <DialogContent>
          <div className="adduser_bg">
            <div className="user_form">
              <div className="delete_sales_list">
                <h3>Delete Products List</h3>
                <p>
                  Are you sure you want to delete the Products record from Products
                  list?
                </p>
                <div className="cancel_save_user">
                  <Button
                    disableRipple
                    disableElevation
                    className="cancel"
                    onClick={handleCloseDialog}
                  >
                    Cancel
                  </Button>
                  <Button
                    disableRipple
                    disableElevation
                    className="save_user"
                    onClick={handleConfirmDelete}
                  >
                    Yes, Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>

    
    </React.Fragment>
      <Box sx={{ display: "flex" }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          <Topbar />
          <section className="main_box">
            <div className="table_box">
              <TableContainer component={Paper}>
                <div className="table_inbox">
                  <div className="add_user_list">
                    <div className="add_user_content">
                      <h3>Products</h3>
                    </div>
                    <div className="add_user">
                      <Button
                        variant="outlined"
                        disableRipple
                        disableElevation
                        onClick={handleClickOpen}>
                        <span className="icon">
                          <EnhancedEncryptionIcon />
                        </span>
                        <span className="content">Add Product</span>
                      </Button>
                    </div>
                  </div>

                  <Table
                    className="adduser_table user_table product_table"
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow className="table_head">
                        <TableCell className='first-child'>Sl.No</TableCell>
                        <TableCell align="left">product Category</TableCell>
                        <TableCell align="left">product Name</TableCell>
                        <TableCell align="left">Product Code</TableCell>
                        <TableCell align="left">Line</TableCell>
                        <TableCell align="left">Size</TableCell>
                        <TableCell align="left">Descriptions</TableCell>
                        <TableCell align="center">Manage</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table_body">
                      {currentproducts.map((product, index) => (
                        <TableRow key={product._id}>
                          <TableCell align="left">{(index + 1) + (currentPage - 1) * usersPerPage}</TableCell>
                          <TableCell align="left">{product.productCategory?.productCategory}</TableCell>
                          <TableCell align="left">{product.productName}</TableCell>
                          <TableCell align="left">{product.productCode}</TableCell>
                          <TableCell align="left">{product.line}</TableCell>
                          <TableCell align="left">{product.size}</TableCell>
                          <TableCell align="left">{product.productDescription}</TableCell>
                          <TableCell className="edit_delete" align="right">
                          <Button
                              disableRipple
                              disableElevation
                              onClick={() => handleDeleteClick(product._id)}
                              className="edit_button delete_button"
                            >
                              <DeleteIcon />
                            </Button>
                            <Button
                              disableRipple
                              disableElevation
                              className="edit_button"
                              onClick={() => handleEditClick(product)}>
                              <BorderColorRoundedIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div className="pagination_table">
                    <Pagination
                      count={Math.ceil(products.length / usersPerPage)}
                      page={currentPage}
                      onChange={(event, page) => paginate(page)} />
                  </div>
                </div>
              </TableContainer>
            </div>
          </section>
        </Box>
      </Box>
    </div >
  );
}

export default Products;
