import React from "react";
import UserManagement from "./Pages/User_management";
import ProductCategory from "./Pages/Product_category";
import Products from "./Pages/Products";
import SubstrateType from "./Pages/Substrate_type";
import ProductComponents from "./Pages/Product_component";
import SalesList from "./Pages/Sales_list";
import MonthlyEprReport from "./Pages/Monthly_epr_report";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/Forgot_password";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Annual_epr_report from "./Pages/Annual_epr_report";
import Profile from "./components/Profile";
import ChangePassword from "./components/ChangePassword";

function App() {
  return (
    <>
      <div className="App">
        <ToastContainer />
        <BrowserRouter>
          <Routes>
            <Route path="/user" element={<UserManagement />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route path="/forgot_password" element={<ForgotPassword />} />
            <Route path="/product_category" element={<ProductCategory />} />
            <Route path="/products" element={<Products />} />
            <Route path="/substrate_type" element={<SubstrateType />} />
            <Route path="/product_components" element={<ProductComponents />} />
            <Route path="/sales_list" element={<SalesList />} />
            <Route path="/monthly_epr_report" element={<MonthlyEprReport />} />
            <Route path="/annual_epr_report" element={<Annual_epr_report />} />
            <Route path="/profile" element={<Profile />} />
<Route path="/changepassword" element={<ChangePassword/>}/>
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
