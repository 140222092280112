import { Create } from "@mui/icons-material";
import { Button,Box, IconButton, TextField } from "@mui/material";
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Sidenav from "../layouts/Sidenav";
import Topbar from "../layouts/Topbar";
import "../Styles/pages/profile.css";
import axios from "axios";

const cl ="app-page-profile";
function ChangePassword(){
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
const token=localStorage.getItem("token")
    const handleChangePassword = async () => {
        const formData = {
            currentPassword,
            password,
            confirmPassword,
        };

        try {
            const response = await axios.patch('https://backend-epr.vibhotech.in/api/v1/users/updateMyPassword', formData,
            {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              });
            console.log('Password change response:', response.data);
            toast.success(response.data.message);
            setCurrentPassword('');
            setPassword('');
            setConfirmPassword('');
        } catch (error) {
            console.error('Error changing password:', error);
            toast.error(error.response.data.error);
        }
    };

    return(
        <>
         <Box sx={{ display: "flex" }}>
        <Sidenav/>
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          <Topbar />
            <div className={`${cl}`}>
                <div className={`${cl}__wrapper`}>
                    <div className={`${cl}__card`}>
                        <h2 style={{margin:0}}>Change Password</h2>
                        <div style={{marginTop:10}} className={`${cl}__info`}>
                            <ul className={`${cl}__infolist`}>
                                <li className={`${cl}__infolistitem`}>
                                    <TextField
                                        type="password"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                        id="standard-basic"
                                        label="Old Password"
                                        variant="standard"
                                    />
                                </li>
                                <li className={`${cl}__infolistitem`}>
                                <TextField
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        id="standard-basic"
                                        label="New Password"
                                        variant="standard"
                                    />
                                </li>
                                <li className={`${cl}__infolistitem`}>
                                <TextField
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        id="standard-basic"
                                        label="Verify Password"
                                        variant="standard"
                                    />
                                </li>
                                <li className={`${cl}__infolistitem --button`}>
                                <Button onClick={handleChangePassword} variant="contained">
                                        Update Password
                                    </Button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            </Box>

</Box>
            <ToastContainer position="top-right" />
        </> 
    )
}
export default ChangePassword;
