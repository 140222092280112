import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button } from "@mui/material";
import Sidenav from "../layouts/Sidenav";
import Topbar from "../layouts/Topbar";
import "../Styles/pages/user_management.css";
import "../Styles/pages/monthly_epr_report.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import pdf from "../assets/images/icons/pdf.svg";
import excel from "../assets/images/icons/excel.svg";
import Filter from "../components/Filter";

function Monthly_epr_report() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  const [monthlyData, setMonthlyData] = useState([]);

  const getData = async (month, year) => {
    try {
      let url = "https://backend-epr.vibhotech.in/api/v1/reports/monthlyReports";
      if (month !== undefined && year !== undefined) {
        const formattedMonth = month.toString().padStart(2, '0');
        url += `/?month=${formattedMonth}&year=${year}`;
      }
      const response = await axios.get(url);
      setMonthlyData(response.data.data.monthlyReports);
    } catch (error) {
      console.error("Error fetching sales:", error.message);
    }
  };
  

  useEffect(() => {
    getData(); // Initial fetch without any filters
  }, []);

  // Function to handle PDF download
  const handleDownloadPdf = async (year, month) => {
    await downloadReport(year, month, "pdf");
  };

  // Function to handle Excel download
  const handleDownloadExcel = async (year, month) => {
    await downloadReport(year, month, "excel");
  };

  // Function to download report
  const downloadReport = async (year, month, format) => {
    try {
      const response = await axios.get(
        `https://backend-epr.vibhotech.in/api/v1/reports/monthly/${year}/${month}/${format}`,
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type:
          format === "excel"
            ? "application/vnd.ms-excel"
            : "application/pdf",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `monthly_report_${year}_${month}.${format === "excel" ? "xls" : "pdf"}`
      ); // Set the download filename with .xls extension
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(`Error downloading ${format}:`, error.message);
    }
  };

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5); // Number of users to display per page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentsubstrate = monthlyData.slice(
    indexOfFirstUser,
    indexOfLastUser
  );
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="monthly_epr_report">
      <Box sx={{ display: "flex" }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          <Topbar />
          <section className="main_box">
            <div className="table_box">
              <TableContainer component={Paper}>
                <div className="table_inbox">
                  <div className="add_user_list">
                    <div className="add_user_content">
                      <h3>Monthly EPR Report</h3>
                    </div>
                    <div className="filter">
                      {/* Pass getData function to Filter component */}
                      <Filter getData={getData} />
                    </div>
                  </div>
                  <Table
                    className="adduser_table user_table product_table"
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow className="table_head">
                        <TableCell className="first-child">Sl.No</TableCell>
                        <TableCell align="left">Substrate</TableCell>
                        <TableCell align="left">Sales Volume</TableCell>
                        <TableCell align="left">Weight</TableCell>
                        <TableCell align="left">Month</TableCell>
                        <TableCell align="left">Download Report</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table_body">
                      {currentsubstrate.map((monthlyReport, index) => (
                        <TableRow key={monthlyReport._id}>
                          <TableCell align="left">{index + 1}</TableCell>
                          <TableCell align="left">
                            {monthlyReport.substrateData[0].substrate}
                          </TableCell>
                          <TableCell align="left">
                            {monthlyReport.substrateData[0].salesVolume}
                          </TableCell>
                          <TableCell align="left">
                            {monthlyReport.substrateData[0].substrateWeight}
                          </TableCell>
                          <TableCell align="left">
                            {monthlyReport.month}/{monthlyReport.year}
                          </TableCell>
                          <TableCell className="download_pdf" align="left">
                            <div className="menu_item">
                              <Button
                                id={`demo-positioned-button-${index}`}
                                aria-controls={
                                  open
                                    ? `demo-positioned-menu-${index}`
                                    : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={(event) => handleClick(event, index)}
                                disableRipple
                                disableElevation
                              >
                                <div className="menu_user">
                                  <span>Download</span>
                                  <ExpandMoreIcon />
                                </div>
                              </Button>
                              <Menu
                                className="download_pdf_menu"
                                id={`demo-positioned-menu-${index}`}
                                aria-labelledby={`demo-positioned-button-${index}`}
                                anchorEl={anchorEl}
                                open={open && selectedIndex === index}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <MenuItem
                                  className="pdf"
                                  onClick={() =>
                                    handleDownloadPdf(
                                      monthlyReport.year,
                                      monthlyReport.month
                                    )
                                  }
                                >
                                  <img src={pdf} alt="" />
                                  Pdf
                                </MenuItem>
                                <MenuItem
                                  className="pdf"
                                  onClick={() =>
                                    handleDownloadExcel(
                                      monthlyReport.year,
                                      monthlyReport.month
                                    )
                                  }
                                >
                                  <img src={excel} alt="" />
                                  Excel
                                </MenuItem>
                              </Menu>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <div className="pagination_table">
                    <Pagination
                      count={Math.ceil(monthlyData.length / usersPerPage)}
                      page={currentPage}
                      onChange={(event, page) => paginate(page)}
                    />
                  </div>
                </div>
              </TableContainer>
            </div>
          </section>
        </Box>
      </Box>
    </div>
  );
}

export default Monthly_epr_report;
