import React from "react";
import "../Styles/layouts/topnav.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import menususer from '../assets/images/icons/menu_user.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';


function Topbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate()

  const handleLogout = () => {
    // Clear the token from local storage
    localStorage.removeItem('token');
  
    // Navigate to the login page
    navigate("/login");
  }
  

  return (
    <div>
      <section className="right_bar">
        <div className="topnav_bar">
          <ul>
            <li className="topnav_bar_list">
              {/* <h3>User Management</h3> */}
            </li>
            <li className="topnav_bar_list">
              <div className="menu_item">
                <Button 
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  disableRipple disableElevation
                >
                  <div className="menu_user">
                  <img src={menususer} alt=""/>
                  <ExpandMoreIcon/>
                  </div>
                </Button>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem  onClick={()=>{navigate("/profile")}}>Profile</MenuItem>
                  <MenuItem  onClick={()=>{navigate("/changepassword")}}>Change Password</MenuItem>
                  <MenuItem onClick={()=>{ handleLogout() }}>Logout</MenuItem>
                </Menu>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default Topbar;
