import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Sidenav from "../layouts/Sidenav";
import Topbar from "../layouts/Topbar";
import "../Styles/pages/user_management.css";
import "../Styles/pages/product_components.css";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import category from "../assets/images/icons/category.svg";
import product_bg from "../assets/images/icons/product_bg.svg";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import axios from "axios";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import DeleteIcon from "@mui/icons-material/Delete";
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Product_components() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);

  };
  const handleClose = () => {
    setOpen(false);
    setproductName("");
    setproductCategory("");
    setproductCode("");
    setproductComponentCategory("");
    setproductComponentName("");
    setitemCode("");
    setusage("");
    setUOM("");
    setsubstrateDescription("");
    setweight("");
    setrecycledContent("");
    setsubstrateType("");
    setsubstrateWeightPercentage("");
    setproductComponentCode("");
    clearValidationErrors();
  };
  
 
  const [open1, setOpen1] = React.useState(false);
  const [selectedProductComponent, setSelectedProductComponent] = useState(null);


  const handleClickOpen1 = (productComponent) => {
    setSelectedProductComponent(productComponent);
    setOpen1(true);
    console.log(productComponent)
  };
  const handleClose1 = () => {
    setOpen1(false);
  };


  //get productcomponent 
  const [productcomponents, setproductcomponents] = useState([]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(9); // Change the number to the desired value // Number of users to display per page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentproductcomponents = productcomponents.slice(indexOfFirstUser, indexOfLastUser);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(productcomponents.length / usersPerPage);


  useEffect(() => {
    getproductcomponents()
  }, [])
  const getproductcomponents = async () => {
    try {
      const response = await axios.get("https://backend-epr.vibhotech.in/api/v1/productComponents");
      setproductcomponents(response.data.productComponents)
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching productcomponents:', error.message);
    }
  }

  //create productcomponent
  const [editingproductcomponents, setEditingproductcomponents] = useState(null);
  const [productName, setproductName] = useState();
  const [productCategory, setproductCategory] = useState();
  const [productCode, setproductCode] = useState("");
  const [productComponentCode, setproductComponentCode] = useState("");
  const [productComponentCategory, setproductComponentCategory] = useState("");
  const [productComponentName, setproductComponentName] = useState("");
  const [itemCode, setitemCode] = useState("");
  const [usage, setusage] = useState("");
  const [UOM, setUOM] = useState("");
  const [substrateDescription, setsubstrateDescription] = useState("");
  const [weight, setweight] = useState("");
  const [recycledContent, setrecycledContent] = useState("");
  const [substrateType, setsubstrateType] = useState("");
  const [substrateWeightPercentage, setsubstrateWeightPercentage] = useState("");

  //validation 
  const [productNameError, setproductNameError] = useState("");
  const [productCategoryError, setproductCategoryError] = useState("");
  const [productCodeError, setproductCodeError] = useState("");
  const [productComponentCodeError, setproductComponentCodeError] = useState("");
  const [productComponentCategoryError, setproductComponentCategoryError] = useState("");
  const [productComponentNameError, setproductComponentNameError] = useState("");
  const [itemCodeError, setitemCodeError] = useState("");
  const [usageError, setusageError] = useState("");
  const [UOMError, setUOMError] = useState("");
  const [substrateDescriptionError, setsubstrateDescriptionError] = useState("");
  const [weightError, setweightError] = useState("");
  const [recycledContentError, setrecycledContentError] = useState("");
  const [substrateTypeError, setsubstrateTypeError] = useState("");
  const [substrateWeightPercentageError, setsubstrateWeightPercentageError] = useState("");
  const clearValidationErrors = () => {
    setproductNameError("");
    setproductCategoryError("");
    setproductCodeError("");
    setproductComponentCodeError("");
    setproductComponentCategoryError("");
    setproductComponentNameError("");
    setitemCodeError("");
    setusageError("");
    setUOMError("");
    setsubstrateDescriptionError("");
    setweightError("");
    setrecycledContentError("");
    setsubstrateTypeError("");
    setsubstrateWeightPercentageError("");
  };
  const validateForm = () => {
    let isValid = true;
  
    // product name validation
    if (!productName) {
      setproductNameError("Please select a product name");
      isValid = false;
    } else {
      setproductNameError("");
    }
  
    // Validate productCategory
    if (!productCategory) {
      setproductCategoryError("Please select a product category");
      isValid = false;
    } else {
      setproductCategoryError("");
    }
    if (!productComponentCategory) {
      setproductComponentCategoryError("Please select a product component category");
      isValid = false;
    } else {
      setproductComponentCategoryError("");
    }
    // Product code validation
    if (!productCode) {
      setproductCodeError("Please select a productCode");
      isValid = false;
    } else {
      setproductCodeError("");
    }
  
    // productComponentCode validation
    if (!productComponentCode) {
      setproductComponentCodeError("Please enter productComponentCode");
      isValid = false;
    } else {
      setproductComponentCodeError("");
    }
  
    //productComponentName validation
    if (!productComponentName) {
      setproductComponentNameError("Please enter productComponentName");
      isValid = false;
    } else {
      setproductComponentNameError("");
    }
  
    // Product itemCode validation
const itemRegex = /^\d+$/; // Regex to match only numbers
if (!itemCode) {
  setitemCodeError("Please enter product itemCode");
  isValid = false;
} else if (!itemRegex.test(itemCode.toString().trim())) { // Convert itemCode to string before calling trim()
  setitemCodeError("Product item must contain only numbers");
  isValid = false;
} else {
  setitemCodeError("");
}

  
    //usage validation
    const usageRegex = /^\d+$/; // Regex to match only numbers
    if (!usage) {
      setusageError("Please enter usage");
      isValid = false;
    } else if (!usageRegex.test(usage.toString().trim())) {
      setusageError("Usage must contain only numbers");
      isValid = false;
    } else {
      setusageError("");
    }
  
    //UOM validation
    if (!UOM) {
      setUOMError("Please enter UOM");
      isValid = false;
    } else {
      setUOMError("");
    }
  
    //substrateDescription validation
    if (!substrateDescription) {
      setsubstrateDescriptionError("Please enter substrateDescription");
      isValid = false;
    } else {
      setsubstrateDescriptionError("");
    }
  
    //weight validation
    if (!weight) {
      setweightError("Please enter weight");
      isValid = false;
    } else {
      setweightError("");
    }
  
    //recycledContent validation
    if (!recycledContent) {
      setrecycledContentError("Please enter recycledContent");
      isValid = false;
    } else {
      setrecycledContentError("");
    }
  
    //substrateType validation
    if (!substrateType) {
      setsubstrateTypeError("Please enter substrateType");
      isValid = false;
    } else {
      setsubstrateTypeError("");
    }
  
    //substrateWeightPercentage validation
    if (!substrateWeightPercentage) {
      setsubstrateWeightPercentageError(
        "Please enter substrateWeightPercentage"
      );
      isValid = false;
    } else {
      setsubstrateWeightPercentageError("");
    }
  
    return isValid;
  };

  const addproductcomponents = async () => {

    if (!validateForm()) {
      
      return;
    }
    try {
      const response = await axios.post("https://backend-epr.vibhotech.in/api/v1/productComponents", {
        productName,
        productCategory,
        productCode,
        productComponentCode,
        productComponentCategory,
        productComponentName,
        itemCode,
        usage,
        UOM,
        substrateDescription,
        weight,
        recycledContent,
        substrateType,
        substrateWeightPercentage
      },
        {
          headers: {
            "Content-Type": "application/json"
          }
        })
      console.log(response.data.productComponents)
      //clear input fileds
      setproductName();
      setproductCategory();
      setproductCode();
      setproductComponentCategory();
      setproductComponentName();
      setitemCode();
      setusage();
      setUOM();
      setsubstrateDescription();
      setweight();
      setrecycledContent();
      setsubstrateType();
      setsubstrateWeightPercentage();
      setproductComponentCode();
      //refresh
      getproductcomponents();
      handleClose();
    } catch (error) {
      console.error('Error adding productcomponents:', error.message);
      toast.error("Error adding productcomponents")
    }
  }


  // API call for updating substrates
  //edit function
  const handleEditClick = (productComponents) => {
    setEditingproductcomponents(productComponents);
    console.log(productComponents._id)
    // Pre-fill the input fields with the existing data
    setproductName(productComponents.productName?._id || "");
    setproductCategory(productComponents.productCategory?._id || "");
    setproductCode(productComponents.productCode || "");
    setproductComponentCategory(productComponents.productComponentCategory || "");
    setproductComponentName(productComponents.productComponentName || "");
    setitemCode(productComponents.itemCode || "");
    setusage(productComponents.usage || "");
    setUOM(productComponents.UOM || "");
    setsubstrateDescription(productComponents.substrateDescription || "");
    setweight(productComponents.weight || "");
    setrecycledContent(productComponents.recycledContent || "");
    setsubstrateType(productComponents?.substrateType?._id || "");
    setsubstrateWeightPercentage(productComponents.substrateWeightPercentage || "");
    setsubstrateDescription(productComponents.substrateDescription || "");
    setproductComponentCode(productComponents.productComponentCode || "");
    //modal open
    setOpen(true);
  };

  const updateproductcomponents = async () => {
    if (!validateForm()) {
      
      return;
    }
    try {
      const response = await axios.patch(`https://backend-epr.vibhotech.in/api/v1/productComponents/${editingproductcomponents._id}`,
        {
          productName,
          productCategory,
          productCode,
          productComponentCode,
          productComponentCategory,
          productComponentName,
          itemCode,
          usage,
          UOM,
          substrateDescription,
          weight,
          recycledContent,
          substrateType,
          substrateWeightPercentage
        }, {
        headers: {
          "Content-Type": "application/json",
        },
      }
      );
      console.log(response.data);
      setproductName();
      setproductCategory();
      setproductCode();
      setproductComponentCategory();
      setproductComponentName();
      setitemCode();
      setusage();
      setUOM();
      setsubstrateDescription();
      setweight();
      setrecycledContent();
      setsubstrateType();
      setsubstrateWeightPercentage();
      setproductComponentCode();
      getproductcomponents();
      handleClose();
      editingproductcomponents("")
    } catch (error) {
      console.error("Error updating substrate:", error.message);
      toast.error("Error updating productcomponents")
    }
  }


  //get products
  const [products, setproducts] = useState([]);
  useEffect(() => {
    getproducts();
  }, []);

  const getproducts = async () => {
    try {
      const response = await axios.get('https://backend-epr.vibhotech.in/api/v1/products');
      setproducts(response.data.products);
      console.log(response.data.products)
    } catch (error) {
      console.error('Error fetching products:', error.message);
    }
  };

  //get the productcategories
  const [productCategories, setProductCategories] = useState([]);

  useEffect(() => {
    getProductCategories();
  }, []);

  const getProductCategories = async () => {
    try {
      const response = await axios.get('https://backend-epr.vibhotech.in/api/v1/productCategories');
      setProductCategories(response.data.productCategories);
      console.log(response.data.productCategories)

    } catch (error) {
      console.error('Error fetching product categories:', error.message);
    }
  };

  //get substrate data
  const [substrate, setsubstrate] = useState([]);

  useEffect(() => {
    getsubstrate()
  }, []);

  const getsubstrate = async () => {
    try {
      const response = await axios.get("https://backend-epr.vibhotech.in/api/v1/substrates");
      setsubstrate(response.data.substrates);
      console.log(response.data.substrates);
    }
    catch (error) {
      console.error('Error fetching substrates:', error.message);
    }
  }
  const [openDialog, setOpenDialog] = useState(false);
  const [componentIdToDelete, setcomponentIdToDelete] = useState(null);

  // Call your delete API or function here


  const handleDeleteClick = (componentId) => {
    setcomponentIdToDelete(componentId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setcomponentIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(
        `https://backend-epr.vibhotech.in/api/v1/productComponents/${componentIdToDelete}`
      );
      // Refresh the substrate list after deletion
      getproductcomponents();
      console.log(`component with ID ${componentIdToDelete} deleted successfully.`);
    }  catch (error) {
      console.error(`Error deleting component with ID ${componentIdToDelete}:`, error.message);
      toast.error("Error deleting productcomponents")
    } finally {
      handleCloseDialog();
    }
  };



  return (
    <div className="user_bg_form">
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {editingproductcomponents ? " Update Product Component" : " Add Product Component"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div className="adduser_bg">
              <div className="user_form">
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="Product Name">Product Name </InputLabel>
                      <Select
                      className="product_name"
                        id="Product Name"
                        label="Select Product Name"
                        value={productName || ''}
                        onChange={(e) => {
                          setproductName(e.target.value);
                          setproductNameError("");
                        }}
                      >
                        {products.map((products) => (
                          <MenuItem key={products._id} value={products._id}>
                            {products.productName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={Boolean(productNameError)}>
                        {productNameError}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select Product Category </InputLabel>
                      <Select
                        id="product-category"
                        label="Select Product Category"
                        type="text"
                        value={productCategory || ''}
                        onChange={(e) => {
                          setproductCategory(e.target.value);
                          setproductCategoryError("");
                        }}
                      >
                        {productCategories.map((category) => (
                          <MenuItem key={category._id} value={category._id}>
                            {category.productCategory}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={Boolean(productCategoryError)}>
                        {productCategoryError}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Product Code"
                      variant="outlined"
                      value={productCode}
                      onChange={(e) => {
                        setproductCode(e.target.value);
                        setproductCodeError("")
                      }}
                    />
                    <FormHelperText error={Boolean(productCodeError)}>
                      {productCodeError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select Product Component Category</InputLabel>
                      <Select
                        id="outlined-basic"
                        label="Select Product Component Category"
                        variant="outlined"
                        value={productComponentCategory}
                        onChange={(e) => {
                          setproductComponentCategory(e.target.value);
                          setproductComponentCategoryError("");
                        }}
                      >
                        <MenuItem value="Primary">Primary</MenuItem>
                        <MenuItem value="Secondary">Secondary</MenuItem>
                        <MenuItem value="Tertiary">Tertiary</MenuItem>
                      </Select>
                      <FormHelperText error={Boolean(productComponentCategoryError)}>
                        {productComponentCategoryError}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Product Component Name"
                      variant="outlined"
                      value={productComponentName}
                      onChange={(e) => {
                        setproductComponentName(e.target.value);
                        setproductComponentNameError("")
                      }}
                    />
                    <FormHelperText error={Boolean(productComponentNameError)}>
                      {productComponentNameError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Item Code"
                      variant="outlined"
                      value={itemCode}
                      onChange={(e) => {
                        setitemCode(e.target.value);
                        setitemCodeError("")
                      }}
                    />
                    <FormHelperText error={Boolean(itemCodeError)}>
                      {itemCodeError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Product Component Code"
                      variant="outlined"
                      value={productComponentCode}
                      onChange={(e) => {
                        setproductComponentCode(e.target.value);
                        setproductComponentCodeError("")
                      }}
                    />
                    <FormHelperText error={Boolean(productComponentCodeError)}>
                      {productComponentCodeError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Usage"
                      variant="outlined"
                      value={usage}
                      onChange={(e) => {
                        setusage(e.target.value);
                        setusageError("")
                      }}
                    />
                    <FormHelperText error={Boolean(usageError)}>
                      {usageError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Enter UOM"
                      variant="outlined"
                      value={UOM}
                      onChange={(e) => {
                        setUOM(e.target.value);
                        setUOMError("")
                      }}
                    />
                    <FormHelperText error={Boolean(UOMError)}>
                      {UOMError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Substrate Description"
                      variant="outlined"
                      value={substrateDescription}
                      onChange={(e) => {
                        setsubstrateDescription(e.target.value);
                        setsubstrateDescriptionError("")
                      }}
                    />
                    <FormHelperText error={Boolean(substrateDescriptionError)}>
                      {substrateDescriptionError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Weight"
                      variant="outlined"
                      value={weight}
                      onChange={(e) => {
                        setweight(e.target.value);
                        setweightError("")
                      }}
                    />
                    <FormHelperText error={Boolean(weightError)}>
                      {weightError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Recycled Content"
                      variant="outlined"
                      value={recycledContent}
                      onChange={(e) => {
                        setrecycledContent(e.target.value);
                        setrecycledContentError("")
                      }}
                    />
                    <FormHelperText error={Boolean(recycledContentError)}>
                      {recycledContentError}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Select Substrate Type </InputLabel>
                      <Select
                        id="outlined-basic"
                        label="Select Substrate Type"
                        variant="outlined"
                        value={substrateType || ''}
                        onChange={(e) => {
                          setsubstrateType(e.target.value);
                          setsubstrateTypeError("");
                        }}
                      >
                        {substrate.map((substrate) => (
                          <MenuItem key={substrate._id} value={substrate._id}>
                            {substrate?.substrateName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={Boolean(substrateTypeError)}>
                        {substrateTypeError}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Substrate Weight Percentage"
                      variant="outlined"
                      value={substrateWeightPercentage}
                      onChange={(e) => {
                        setsubstrateWeightPercentage(e.target.value);
                        setsubstrateWeightPercentageError("")
                      }}
                    />
                    <FormHelperText error={Boolean(substrateWeightPercentageError)}>
                      {substrateWeightPercentageError}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="cancel_save_user">
              <Button disableRipple disableElevation className="cancel" onClick={handleClose}>
                Cancel
              </Button>
              <Button disableRipple disableElevation className='save_user' onClick={editingproductcomponents ? updateproductcomponents : addproductcomponents}>{editingproductcomponents ? "Update product Components" : "Save product Components"}</Button>
            </div>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
      <React.Fragment>
        <BootstrapDialog className="sales_list"
          onClose={handleClose1}
          aria-labelledby="customized-dialog-title"
          open={open1}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
          ></DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose1}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div className="adduser_bg">
              <div className="user_form">
                <div className="add_product_name">
                  <div className="add_product_name_content">
                    <div className="add_product_name_list">
                      <div className="product">
                        <h3>{selectedProductComponent?.productName?.productName}</h3>
                        <h4>ID - {selectedProductComponent?._id}</h4>
                        <h5>
                          <span>
                            <img src={category} alt="" />
                          </span>
                          {selectedProductComponent?.productCategory?.productCategory}
                        </h5>
                      </div>
                      <div className="view_edit">
                        <Button disableRipple disableElevation className="edit">
                          <BorderColorRoundedIcon />
                        </Button>
                      </div>
                    </div>
                    <div className="product_item_code">
                      <div className="product_item_code_content">
                        <h4>Product Component Code</h4>
                        <h5>{selectedProductComponent?.productComponentCode}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>Product  Component Category</h4>
                        <h5>{selectedProductComponent?.productComponentCategory}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>Item Code</h4>
                        <h5>{selectedProductComponent?.itemCode}</h5>
                      </div>
                    </div>
                    <div className="product_item_code">
                      <div className="product_item_code_content">
                        <h4>Component Weight</h4>
                        <h5>{selectedProductComponent?.weight}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>Usage</h4>
                        <h5>{selectedProductComponent?.usage}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>Recycled Content</h4>
                        <h5>{selectedProductComponent?.recycledContent}</h5>
                      </div>
                    </div>
                    <div className="product_item_code">
                      <div className="product_item_code_content">
                        <h4>Substrate Type</h4>
                        <h5>{selectedProductComponent?.substrateType?.substrateName}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>UOM</h4>
                        <h5>{selectedProductComponent?.UOM}</h5>
                      </div>
                      <div className="product_item_code_content">
                        <h4>Substrate Weight Percentage </h4>
                        <h5>{selectedProductComponent?.substrateWeightPercentage}</h5>
                      </div>
                    </div>
                    <div className="substrate_description">
                      <h5>Substrate Description</h5>
                      <p>{selectedProductComponent?.substrateDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cancel_save_user">
              <Button disableRipple disableElevation className="cancel" onClick={handleClose1}>
                Cancel
              </Button>
              <Button disableRipple disableElevation className="save_user">
                Save
              </Button>
            </div>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
      <React.Fragment>
      <BootstrapDialog
        className="sales_delete"
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <DialogContent>
          <div className="adduser_bg">
            <div className="user_form">
              <div className="delete_sales_list">
                <h3>Delete product components List</h3>
                <p>
                  Are you sure you want to delete the components record from product components
                  list?
                </p>
                <div className="cancel_save_user">
                  <Button
                    disableRipple
                    disableElevation
                    className="cancel"
                    onClick={handleCloseDialog}
                  >
                    Cancel
                  </Button>
                  <Button
                    disableRipple
                    disableElevation
                    className="save_user"
                    onClick={handleConfirmDelete}
                  >
                    Yes, Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>

    
    </React.Fragment>
      <Box sx={{ display: "flex" }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          <Topbar />
          <section className="main_box">
            <div className="table_box">
              <TableContainer component={Paper}>
                <div className="table_inbox">
                  <div className="add_user_list">
                    <div className="add_user_content">
                      <h3>Product Component</h3>
                    </div>
                    <div className="add_user">
                      <Button
                        variant="outlined"
                        disableRipple
                        disableElevation
                        onClick={handleClickOpen}
                      >
                        <span className="icon">
                          <AddRoundedIcon />
                        </span>
                        <span className="content">Add Product Component</span>
                      </Button>
                    </div>
                  </div>
                  <div className="product_components">
                    <Grid container spacing={2}>
                      {currentproductcomponents.map((productComponent, index) => (
                        <Grid key={productComponent._id} item xs={4} >
                          <div className="product_components_box" >
                            <div className="bg">
                              <img src={product_bg} alt="" />
                            </div>
                            <div className="product_components_box_main">
                              <div className="product_components_box_item">
                                <h3>{productComponent.productName?.productName}</h3>
                                <h4>code:{productComponent.productCode}</h4>
                                <h5>
                                  <span>
                                    <img src={category} alt="" />
                                  </span>
                                  {productComponent.productComponentCategory}
                                </h5>
                              </div>
                              <div className="view_edit">
                                <Button
                                  disableRipple
                                  disableElevation
                                  className="view"
                                  onClick={() => handleClickOpen1(productComponent)}
                                >
                                  View
                                </Button>
                                <Button
                                  disableRipple
                                  disableElevation
                                  className="edit"
                                  onClick={() => handleEditClick(productComponent)} >
                                  <BorderColorRoundedIcon />
                                </Button>
                                <Button
                              disableRipple
                              disableElevation
                              onClick={() => handleDeleteClick(productComponent._id)}
                              className="edit"
                            >
                              <DeleteIcon />
                            </Button>
                              </div>
                            </div>
                            <div className="component_weight">
                              <div className="component_weight_item">
                                <h4>Component Weight</h4>
                                <h5>{productComponent.weight}</h5>
                              </div>
                              <div className="component_weight_item component_weight_item2">
                                <h4>Substrate Type</h4>
                                <h5>{productComponent.substrateType?.substrateName}</h5>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                  <div className="pagination_table">
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={(event, page) => paginate(page)} />
                  </div>
                </div>
              </TableContainer>
            </div>
          </section>
        </Box>
      </Box>
    </div>
  );
}

export default Product_components;
