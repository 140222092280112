import { Box, Button, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import Sidenav from "../layouts/Sidenav";
import Topbar from "../layouts/Topbar";
import "../Styles/pages/user_management.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
// import InputLabel from '@mui/material/InputLabel';
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import active from "../assets/images/icons/active.svg";
import inactive from "../assets/images/icons/inactive.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function User_management() {
  const [open, setOpen] = React.useState(false);
  const [editinguser, setEditinguser] = useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    // Reset the state values for user inputs
    setuserName("");
    setuserEmail("");
    setUserPassword("");
    setUserConfirmPassword("");
    setEditinguser("");
    // Close the dialog
    setOpen(false);
  };

  //  api call for get userdata
  const [users, setUsers] = useState([]);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10); // Number of users to display per page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [userName, setuserName] = useState();
  const [userEmail, setuserEmail] = useState();
  const [userPassword, setUserPassword] = useState("");
  const [userConfirmPassword, setUserConfirmPassword] = useState("");

  const [status, setStatus] = useState("Active");
  const [userStatuses, setUserStatuses] = useState({});

  const handleStatusChange = (event, userId) => {
    const selectedStatus = event.target.value;
    updateUserStatus(userId, selectedStatus);
  };
  const handleEditClick = (user) => {
    setEditinguser(user);
    console.log(user._id);

    setuserName(user.name || "");
    setuserEmail(user.email || "");

    setOpen(true);
  };
  const token = localStorage.getItem("token");
  const updateUser = async () => {
    try {
      if (!userName || !userEmail) {
        toast.error("All fields are mandatory");
        return;
      }

      // Check if email is valid using regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(userEmail)) {
        toast.error("Invalid email format");
        return;
      }

      const response = await axios.patch(
        `https://backend-epr.vibhotech.in/api/v1/users/${editinguser._id}`,
        {
          name: userName,
          email: userEmail,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("user updated successfully");
      handleClose();
      getUsers();
    } catch (error) {
      console.error("Error updating user:", error.message);
      toast.error("Error updating user")
    }
  };
  const updateUserStatus = async (userId, status) => {
    try {
      const response = await axios.patch(
        `https://backend-epr.vibhotech.in/api/v1/users/${userId}`,
        {
          status: status,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update the status of the user in the local state
      setUserStatuses((prevStatuses) => ({
        ...prevStatuses,
        [userId]: status,
      }));
      getUsers();
    } catch (error) {
      console.error("Error updating user status:", error.message);
      toast.error("Error updating user status")
    }
  };
  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const response = await axios.get("https://backend-epr.vibhotech.in/api/v1/users");
      const fetchedUsers = response.data.users;

      // Initialize userStatuses with the statuses of fetched users
      const initialUserStatuses = {};
      fetchedUsers.forEach((user) => {
        initialUserStatuses[user._id] = user.status;
      });

      setUsers(fetchedUsers);
      setUserStatuses(initialUserStatuses); // Set the initial statuses
    } catch (error) {
      console.error("Error fetching users:", error.message);
    }
  };

  const addUser = async () => {
    try {
      if (!userName || !userEmail || !userPassword || !userConfirmPassword) {
        toast.error("All fields are mandatory");
        return;
      }

      // Check if email is valid using regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(userEmail)) {
        toast.error("Invalid email format");
        return;
      }

      // Check if password and confirmPassword match
      if (userPassword !== userConfirmPassword) {
        toast.error("Passwords do not match");
        return;
      }

      // Check if password length is at least 8 characters
      if (userPassword.length < 8) {
        toast.error("Password must be at least 8 characters long");
        return;
      }
      const response = await axios.post(
        "https://backend-epr.vibhotech.in/api/v1/users/signup",
        {
          name: userName,
          email: userEmail,
          password: userPassword,
          confirmPassword: userConfirmPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // If the user is successfully added, display a success message
      toast.success("User added successfully");

      // Close the dialog
      handleClose();

      // Refresh the list of users to display the newly added user
      getUsers();
    } catch (error) {
      console.error("Error adding user:", error.message);
      toast.error("Error adding user")
    }
  };

  return (
    <div className="user_bg_form">
      <React.Fragment>
        <BootstrapDialog
          className="sales_entry"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {editinguser ? "Update User" : "Add New User"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div className="adduser_bg">
              <div className="user_form">
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <TextField
                      id="outlined-basic"
                      label="User Name *"
                      variant="outlined"
                      value={userName}
                      onChange={(e) => setuserName(e.target.value)}
                    />
                  </Grid>
                  {/* <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      label="User ID"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Designation"
                      variant="outlined"
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Email *"
                      variant="outlined"
                      value={userEmail}
                      onChange={(e) => setuserEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {!editinguser && (
                      <TextField
                        id="outlined-basic"
                        label="Enter Password *"
                        variant="outlined"
                        type="password"
                        value={userPassword}
                        onChange={(e) => setUserPassword(e.target.value)}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {!editinguser && (
                      <TextField
                        id="outlined-basic"
                        label="Confirm Password *"
                        variant="outlined"
                        type="password"
                        value={userConfirmPassword}
                        onChange={(e) => setUserConfirmPassword(e.target.value)}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="cancel_save_user">
              <Button disableRipple disableElevation className="cancel">
                Cancel
              </Button>
              <Button
                disableRipple
                disableElevation
                className="save_user"
                onClick={editinguser ? updateUser : addUser}
              >
                {editinguser ? "Update User" : "Save user"}{" "}
              </Button>
            </div>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>

      <Box sx={{ display: "flex" }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          <Topbar />
          <section className="main_box">
            <div className="table_box">
              <TableContainer component={Paper}>
                <div className="table_inbox">
                  <div className="add_user_list">
                    <div className="add_user_content">
                      <h3>User List</h3>
                    </div>
                    <div className="add_user">
                      <Button
                        variant="outlined"
                        disableRipple
                        disableElevation
                        onClick={handleClickOpen}
                      >
                        <span className="icon">
                          <PersonAddAltRoundedIcon />
                        </span>
                        <span className="content">Add User</span>
                      </Button>
                    </div>
                  </div>
                  <Table
                    className="adduser_table user_table"
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow className="table_head">
                        <TableCell>Sl.No</TableCell>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">User ID</TableCell>
                        <TableCell align="left">Designation</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Manage</TableCell>
                        <TableCell align="left">Edit</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table_body">
                      {currentUsers.map((user, index) => (
                        <TableRow key={user._id}>
                          <TableCell align="left">
                            {index + 1 + (currentPage - 1) * usersPerPage}
                          </TableCell>
                          <TableCell align="left">{user.name}</TableCell>
                          <TableCell align="left">{user.email}</TableCell>
                          <TableCell align="left">{user.role}</TableCell>
                          {/* <TableCell align="left"><img src={user.photo} alt="user-photo" /></TableCell> */}
                          <TableCell align="left">{user.status}</TableCell>
                          <TableCell className="active_inactive" align="left">
                            <div className="active_inactive_content">
                              <ul>
                                <li>
                                  {user.status === "Active" ? (
                                    <img src={active} alt="Active" />
                                  ) : (
                                    <img src={inactive} alt="Inactive" />
                                  )}
                                </li>
                                <li>
                                  <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                      {console.log(user)}
                                      <NativeSelect
                                        value={userStatuses[user._id]}
                                        onChange={(event) =>
                                          handleStatusChange(event, user._id)
                                        }
                                        inputProps={{
                                          name: "status",
                                          id: "user-status",
                                        }}
                                      >
                                        <option value="Active">Active</option>
                                        <option value="Inactive">
                                          Inactive
                                        </option>
                                      </NativeSelect>
                                    </FormControl>
                                  </Box>
                                </li>
                              </ul>
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <Button
                              disableRipple
                              disableElevation
                              onClick={() => handleEditClick(user)}
                              className="edit_button"
                            >
                              <BorderColorRoundedIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div className="pagination_table">
                    <Pagination
                      count={Math.ceil(users.length / usersPerPage)}
                      page={currentPage}
                      onChange={(event, page) => paginate(page)}
                    />
                  </div>
                </div>
              </TableContainer>
            </div>
          </section>
        </Box>
      </Box>
    </div>
  );
}

export default User_management;
