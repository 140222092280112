import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid } from "@mui/material";
import pdf from "../assets/images/icons/pdf.svg";
import excel from "../assets/images/icons/excel.svg";
import dayjs from "dayjs";

function Filter2({ getData, handleDownloadPdf, handleDownloadExcel }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [selectedYear, setSelectedYear] = useState(dayjs().year()); // Initialize with current year
console.log(selectedYear)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleYearChange = (date) => {
    if (date !== null && typeof date === 'object') {
      setSelectedYear(date.getFullYear()); 
    }
  };
  
  
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popover" : undefined;

  return (
    <div className="filter_filed">
      <Button
        disableRipple
        disableElevation
        aria-describedby={id}
        onClick={handleClick}
      >
        <FilterAltIcon />
        Filter
      </Button>
      <Button
        disableRipple
        disableElevation
        aria-describedby={id1}
        onClick={handleClick1}
      >
        Download
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>
          <div className="filter_list">
            <div className="filter_list_item">
              <h3>Filter</h3>
              <div className="filter_dates">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
  label="Year"
  views={["year"]}
  value={dayjs(selectedYear, "YYYY")}
  onChange={(date) => {
    if (date !== null) {
      handleYearChange(date.toDate());
    }
  }}
/>


                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </div>
              <div className="rest-apply">
                <Button
                  className="reset"
                  disableRipple
                  disableElevation
                  onClick={handleClose}
                >
                  Reset
                </Button>
                <Button
                  className="apply"
                  disableRipple
                  disableElevation
                  onClick={() => {
                    handleClose();
                    getData(selectedYear);
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
        </Typography>
      </Popover>
      <Popover
        open={open1}
        anchorEl={anchorEl1}
        onClose={handleClose1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography className="download_options_main">
          <div className="download_options">
            <Button
              className="download_pdf"
              onClick={() => handleDownloadPdf(selectedYear)}
            >
              <img src={pdf} alt="PDF" />
              Pdf
            </Button>
            <Button
              className="download_excel"
              onClick={() => handleDownloadExcel(selectedYear)}
            >
              <img src={excel} alt="Excel" />
              Excel
            </Button>
          </div>
        </Typography>
      </Popover>
    </div>
  );
}

export default Filter2;
