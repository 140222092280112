import React, { useState, useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import Topbar from '../layouts/Topbar'
import Sidenav from '../layouts/Sidenav'
import "../Styles/pages/user_management.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import WidgetsIcon from '@mui/icons-material/Widgets';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import Pagination from '@mui/material/Pagination';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Product_category() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //  api call for get userdata
  const [products, setproducts] = useState([]);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10); // Number of users to display per page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentproducts = products.slice(indexOfFirstUser, indexOfLastUser);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [editingcategory, setEditingcategory] = useState(null);

  //get products
  useEffect(() => {
    getproducts();
  }, []);

  const getproducts = async () => {
    try {
      const response = await axios.get('https://backend-epr.vibhotech.in/api/v1/productCategories');
      setproducts(response.data.productCategories);
      console.log(response.data.productCategories)
    } catch (error) {
      console.error('Error fetching users:', error.message);
    }
  };

  //add products
  const [productCategory, setProductCategory] = useState("");

  const addproduct = async () => {
    //validation
    if (!productCategory) {
      toast.error('Please enter product category.');
      return;
    }
    try {
      const response = await axios.post("https://backend-epr.vibhotech.in/api/v1/productCategories", {
        productCategory,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log(response.data);
      setProductCategory()
      getproducts();
      handleClose();
    } catch (error) {
      console.error('Error fetching products:', error.message);
      toast.error("Error adding category")
    }
  }
  const handleEditClick = (category) => {
    setEditingcategory(category);
 
    setProductCategory(category.productCategory || "");
    
    //modal open
    setOpen(true);
  };

  const updatcategory = async () => {
    if (!productCategory) {
      toast.error('Please enter product category.');
      return;
    }
    try {
      const response = await axios.patch(`https://backend-epr.vibhotech.in/api/v1/productCategories/${editingcategory._id}`, {
        productCategory,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log(response.data);
      setProductCategory()
      getproducts();
      handleClose();
    } catch (error) {
      console.error('Error fetching users:', error.message);
      toast.error("Error updating category")
    }
  }
  const [openDialog, setOpenDialog] = useState(false);
  const [categoryIdToDelete, setcategoryIdToDelete] = useState(null);

  // Call your delete API or function here


  const handleDeleteClick = (categoryId) => {
    setcategoryIdToDelete(categoryId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setcategoryIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(
        `https://backend-epr.vibhotech.in/api/v1/productCategories/${categoryIdToDelete}`
      );
      // Refresh the substrate list after deletion
      getproducts();
      console.log(`Category with ID ${categoryIdToDelete} deleted successfully.`);
    }  catch (error) {
      console.error(`Error deleting Category with ID ${categoryIdToDelete}:`, error.message);
      toast.error("Error deleting category")
    } finally {
      handleCloseDialog();
    }
  };


  return (
    <div>
      <BootstrapDialog className='sales_entry'
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {editingcategory? "Update Category" : "Add Category"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div className="adduser_bg">
            <div className="user_form">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    label="Product Category"
                    variant="outlined"
                    value={productCategory}
                    onChange={(e) => setProductCategory(e.target.value)}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="cancel_save_user">
            <Button disableRipple disableElevation className="cancel" onClick={handleClose}>Cancel</Button>
            <Button disableRipple disableElevation className='save_user' onClick= {editingcategory? updatcategory :addproduct}> {editingcategory? "Update user":"Save User"}</Button>
          </div>
        </DialogContent>
      </BootstrapDialog>
      <React.Fragment>
      <BootstrapDialog
        className="sales_delete"
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <DialogContent>
          <div className="adduser_bg">
            <div className="user_form">
              <div className="delete_sales_list">
                <h3>Delete Category Products List</h3>
                <p>
                  Are you sure you want to delete the Category Products record from Category Products
                  list?
                </p>
                <div className="cancel_save_user">
                  <Button
                    disableRipple
                    disableElevation
                    className="cancel"
                    onClick={handleCloseDialog}
                  >
                    Cancel
                  </Button>
                  <Button
                    disableRipple
                    disableElevation
                    className="save_user"
                    onClick={handleConfirmDelete}
                  >
                    Yes, Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>

    
    </React.Fragment>
      <Box sx={{ display: 'flex' }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          <Topbar />
          <section className="main_box">
            <div className="table_box">
              <TableContainer component={Paper}>
                <div className="table_inbox">
                  <div className="add_user_list">
                    <div className="add_user_content">
                      <h3>Product Category</h3>
                    </div>
                    <div className="add_user">
                      <Button
                        variant="outlined"
                        disableRipple
                        disableElevation
                        onClick={handleClickOpen}
                      >
                        <span className="icon">
                          <WidgetsIcon />
                        </span>
                        <span className="content">Add Category</span>
                      </Button>
                    </div>
                  </div>
                  <Table
                    className="adduser_table user_table product_table"
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow className="table_head">
                        <TableCell className='first-child'>Sl.No</TableCell>
                        <TableCell className='second-child'>Product Category ID </TableCell>
                        <TableCell className='third-child'>Product Category </TableCell>
                        <TableCell className='first-child'>Manage</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table_body">
                      {currentproducts.map((Product, index) => (
                        <TableRow key={Product._id}>
                          <TableCell align="left">{(index + 1) + (currentPage - 1) * usersPerPage}</TableCell>
                          {/* <TableCell align="left">01</TableCell> */}
                          <TableCell align="left">{Product._id}</TableCell>
                          <TableCell align="left">{Product.productCategory}</TableCell>
                          <TableCell align="left">
                            <Button
                              disableRipple
                              disableElevation
                              className="edit_button"
                              onClick={() => handleEditClick(Product)}

                            >
                              <BorderColorRoundedIcon />
                            </Button>
                            <Button
                              disableRipple
                              disableElevation
                              onClick={() => handleDeleteClick(Product._id)}
                              className="edit_button delete_button"
                            >
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div className="pagination_table">
                    <Pagination
                      count={Math.ceil(products.length / usersPerPage)}
                      page={currentPage}
                      onChange={(event, page) => paginate(page)} />
                  </div>
                </div>
              </TableContainer>
            </div>
          </section>
        </Box>
      </Box>
    </div>
  )
}

export default Product_category