import * as React from 'react';
import '../Styles/layouts/sidenav.css';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import logo from '../assets/images/logo.svg';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import DiscountIcon from '@mui/icons-material/Discount';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import DescriptionIcon from '@mui/icons-material/Description';
import { useNavigate } from 'react-router-dom';



const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));



const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Sidenav() { 
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate()



  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer  variant="permanent" open={open}>
        <div className='sidebar-main'>
          <div className='sidebar-image'>
               <span onClick={()=>{navigate("/user")}}><img src={logo} alt="" /></span>
          </div>
             <div className='sidebar_menu_list'>
             <div>
                <Accordion onClick={()=>{navigate("/user")}} className="user_management_accordion">
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <PersonRoundedIcon/><span className='product_content_sm'>User Management</span>
                  </AccordionSummary>
                </Accordion>
                <Accordion className="product_setup">
                  <AccordionSummary className="active"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Inventory2Icon/><span className='product_content_sm'>Product Setup </span>
                  </AccordionSummary>
                  <AccordionDetails>
                   <ul>
                    <li onClick={()=>{navigate("/product_category")}}>Product Category</li>
                    <li onClick={()=>{navigate("/products")}}>Products</li>
                    <li onClick={()=>{navigate("/substrate_type")}}>Substrate Type</li>
                    <li onClick={()=>{navigate("/product_components")}}>Product Component </li>
                   </ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion onClick={()=>{navigate("/sales_list")}} className="user_management_accordion">
                  <AccordionSummary
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <DiscountIcon/><span className='product_content_sm'>Sales List</span>
                  </AccordionSummary>
                </Accordion>
                <Accordion className="product_setup">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    <DescriptionIcon/><span className='product_content_sm'>Reports</span>
                  </AccordionSummary>
                  <AccordionDetails>
                  <ul>
                    <li onClick={()=>{navigate("/monthly_epr_report")}}>Monthly EPR Report</li>
                    <li onClick={()=>{navigate("/annual_epr_report")}}>Annual EPR Report</li>
                   </ul>
                  </AccordionDetails>
                </Accordion>
               
              </div>
             </div>
        </div>
      </Drawer>
    </Box>
  );
}