import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Sidenav from "../layouts/Sidenav";
import Topbar from "../layouts/Topbar";
import "../Styles/pages/user_management.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Sales_list() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setentryDate();
    setProductCategory();
    setproductCode();
    setproduct();
    setProducts("");
    setsalesVolume();
    setsize();
    setEditingSales('')
  };
  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  // {{URL}}api/v1/salesEntries
  const [sales, setsales] = useState([]);
  const [editingSales, setEditingSales] = useState(null);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5); // Number of users to display per page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentsales = sales.slice(indexOfFirstUser, indexOfLastUser);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //get sales data
  useEffect(() => {
    getsales();
  }, []);

  const getsales = async () => {
    try {
      const response = await axios.get(
        "https://backend-epr.vibhotech.in/api/v1/salesEntries"
      );
      setsales(response.data.salesEntries);
      console.log(response.data.salesEntries);
    } catch (error) {
      console.error("Error fetching sales:", error.message);
    }
  };

  //add sales
  const [entryDate, setentryDate] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productCode, setproductCode] = useState("");
  const [product, setproduct] = useState("");
  const [salesVolume, setsalesVolume] = useState("");
  const [size, setsize] = useState("");

  const handleEditClick = (sale) => {
    setEditingSales(sale);
    const formattedDate = dayjs(sale.entryDate);
    console.log(formattedDate);

    setentryDate(formattedDate || "");
    setProductCategory(sale.productCategory?._id || "");
    setproduct(sale.productName || "");
    setproductCode(sale.productCode || "");
    setsalesVolume(sale.salesVolume || "");
    setsize(sale.size || "");

    setOpen(true);
};


  
  const updateSales = async () => {
    const formattedDate = dayjs(entryDate).format("YYYY-MM-DD");
    if (!entryDate ||
      !productCategory ||
      !productCode ||
      !product ||
      !salesVolume ||
      !size) {
      toast.error("All fields are required. Please fill in all the fields.");
      return;
  }
  
  if (isNaN(size)) {
      toast.error("Size must be a number. Please enter a valid number for size.");
      return;
  }
    try {
      const response = await axios.patch(
        `https://backend-epr.vibhotech.in/api/v1/salesEntries/${editingSales._id}`,
        {
          entryDate: formattedDate,
          productCategory,
          product,
          productCode,
          size,
          salesVolume,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data);
      setEditingSales("");
      setProductCategory("");
      setproduct("");
      setentryDate("");

      setproductCode("");
      setsize("");
      setsalesVolume("");
      getsales();
      handleClose();

      getsales();
    } catch (error) {
      console.error("Error updating sales:", error.message);
      toast.error("Error updating sales")
    }
  };

  const addsales = async () => {
    const formattedDate = dayjs(entryDate).format("YYYY-MM-DD");

    if (!entryDate ||
      !productCategory ||
      !productCode ||
      !product ||
      !salesVolume ||
      !size) {
      toast.error("All fields are required. Please fill in all the fields.");
      return;
  }
  
  if (isNaN(size)) {
      toast.error("Size must be a number. Please enter a valid number for size.");
      return;
  }
  
    try {
      const response = await axios.post(
        "https://backend-epr.vibhotech.in/api/v1/salesEntries",
        {
          entryDate: formattedDate,
          productCategory,
          productCode,
          product,
          salesVolume,
          size,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    
handleClose()
      setentryDate();
      setProductCategory();
      setproductCode();
      setproduct();
      setsalesVolume();
      setsize();
      //refresh the added substrates
      getsales();
    } catch (error) {
      console.error("Error adding sales:", error.message);
      toast.error("Error adding sales")
    }
  };

  //get the productcategories
  const [productCategories, setProductCategories] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProductCategories();
  }, []);

  const getProductCategories = async () => {
    try {
      const response = await axios.get(
        "https://backend-epr.vibhotech.in/api/v1/productCategories"
      );
      setProductCategories(response.data.productCategories);
      console.log(response.data.productCategories);
    } catch (error) {
      console.error("Error fetching product categories:", error.message);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `https://backend-epr.vibhotech.in/api/v1/products/category/${productCategory}`
        );
        setProducts(response.data.products);
      } catch (error) {
        console.error("Error fetching products:", error.message);
      }
    };

    if (productCategory) {
      fetchProducts();
    }
  }, [productCategory]);
  const [autocompleteKey, setAutocompleteKey] = useState(0);
  const handleProductSelection = (event, newValue) => {
    setproduct(newValue);


    const selectedProduct = products.find(
      (item) => item.productName === newValue
    );


    if (selectedProduct) {
      setproductCode(selectedProduct.productCode);
    }
  };

  const handleProductCategoryChange = (e) => {
    setProductCategory(e.target.value);

    setproduct("");
    setproductCode("");

    setAutocompleteKey((prevKey) => prevKey + 1);
  };
  console.log(productCategory);
  console.log(product);
  console.log(productCode);
  const [openDialog, setOpenDialog] = useState(false);
  const [saleIdToDelete, setSaleIdToDelete] = useState(null);

  const handleDeleteClick = (saleId) => {
    setSaleIdToDelete(saleId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSaleIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(
        `https://backend-epr.vibhotech.in/api/v1/salesEntries/${saleIdToDelete}`
      );
      getsales();
      console.log(`Sale with ID ${saleIdToDelete} deleted successfully.`);
    } catch (error) {
      console.error(
        `Error deleting sale with ID ${saleIdToDelete}:`,
        error.message
      );
      toast.error("Error deleting sales")
    } finally {
      handleCloseDialog();
    }
  };
  return (
    <div className="sales_entry">
      <React.Fragment>
        <BootstrapDialog
          className="sales_entry"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {editingSales ? "update sales" : "Add sales"}
           
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div className="adduser_bg">
              <div className="user_form">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker
                          label="Entry Date *"
                          value={entryDate}
                          onChange={(selectedDate) =>
                            setentryDate(selectedDate)
                          }
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Product Category *
                        </InputLabel>
                        <Select
                          labelId="product-category-label"
                          id="product-category"
                          label='Select Product Category *'
                          value={productCategory}
                          onChange={handleProductCategoryChange}
                        >
                          {productCategories.map((category) => (
                            <MenuItem key={category?._id} value={category?._id}>
                              {category?.productCategory}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        {products.length > 0 ? (
                          <Autocomplete
                            key={autocompleteKey}
                         
                            freeSolo
                            id="product-name"
                            options={products.map(
                              (product) => product.productName
                            )}
                            value={product}
                            onChange={handleProductSelection}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Product Name *"
                              />
                            )}
                          />
                        ) : (
                          <TextField
                            disabled
                            label="Select Product Name *"
                            value="There are no products for this category"
                          />
                        )}
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Product Code *"
                      variant="outlined"
                      value={productCode}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Enter Sales Volume *"
                      variant="outlined"
                      value={salesVolume}
                      onChange={(e) => setsalesVolume(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Size *"
                      variant="outlined"
                      value={size}
                      onChange={(e) => setsize(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="cancel_save_user">
              <Button
                disableRipple
                disableElevation
                className="cancel"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                disableRipple
                disableElevation
                className="save_user"
                onClick={editingSales ? updateSales : addsales}
              >
                {editingSales ? "Update Sales" : "Save Sales"}
              </Button>
            </div>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
      <React.Fragment>
      <BootstrapDialog
        className="sales_delete"
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <DialogContent>
          <div className="adduser_bg">
            <div className="user_form">
              <div className="delete_sales_list">
                <h3>Delete Sales List</h3>
                <p>
                  Are you sure you want to delete the Sales record from Sales
                  list?
                </p>
                <div className="cancel_save_user">
                  <Button
                    disableRipple
                    disableElevation
                    className="cancel"
                    onClick={handleCloseDialog}
                  >
                    Cancel
                  </Button>
                  <Button
                    disableRipple
                    disableElevation
                    className="save_user"
                    onClick={handleConfirmDelete}
                  >
                    Yes, Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>

    
    </React.Fragment>
      <Box sx={{ display: "flex" }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          <Topbar />
          <section className="main_box">
            <div className="table_box">
              <TableContainer component={Paper}>
                <div className="table_inbox">
                  <div className="add_user_list">
                    <div className="add_user_content">
                      <h3>Sales Entry</h3>
                    </div>
                    <div className="add_user">
                      <Button
                        variant="outlined"
                        disableRipple
                        disableElevation
                        onClick={handleClickOpen}
                      >
                        <span className="icon">
                          <AddRoundedIcon />
                        </span>
                        <span className="content">Add Sales</span>
                      </Button>
                    </div>
                  </div>
                  <Table
                    className="adduser_table user_table product_table"
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow className="table_head">
                        <TableCell className="first-child">Sl.No</TableCell>
                        <TableCell align="left">Entry Date</TableCell>
                        <TableCell align="left">Product Category</TableCell>
                        <TableCell align="left">Product Code</TableCell>
                        <TableCell align="left">Product</TableCell>
                        <TableCell align="left">Sales Volume</TableCell>
                        <TableCell align="left">Size</TableCell>
                        <TableCell align="center">Manage</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table_body">
                      {currentsales.map((sale, index) => (
                        <TableRow key={sale._id}>
                          <TableCell align="left">
                            {index + 1 + (currentPage - 1) * usersPerPage}
                          </TableCell>
                          <TableCell align="left">
                            {dayjs(sale.entryDate).format("YYYY-MM-DD")}
                          </TableCell>
                          <TableCell align="left">
                            {sale?.productCategory?.productCategory}
                          </TableCell>
                          <TableCell align="left">{sale.productCode}</TableCell>
                          <TableCell align="left">{sale.productName}</TableCell>
                          <TableCell align="left">{sale.salesVolume}</TableCell>
                          <TableCell align="left">{sale.size}</TableCell>
                          <TableCell className="edit_delete" align="left">
                            <Button
                              disableRipple
                              disableElevation
                              className="edit_button"
                              onClick={() => handleEditClick(sale)}
                            >
                              <BorderColorRoundedIcon />
                            </Button>
                            <Button
                              disableRipple
                              disableElevation
                              onClick={() => handleDeleteClick(sale._id)}
                              className="edit_button delete_button"
                            >
                              <DeleteIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div className="pagination_table">
                    <Pagination
                      count={Math.ceil(sales.length / usersPerPage)}
                      page={currentPage}
                      onChange={(event, page) => paginate(page)}
                    />
                  </div>
                </div>
              </TableContainer>
            </div>
          </section>
        </Box>
      </Box>
    </div>
  );
}

export default Sales_list;
