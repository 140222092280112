import React, { useState } from "react";
import "../Styles/pages/login.css";
import { Button, Container } from "@mui/material";
import login_bg from "../assets/images/login/login_bg.svg";
import logo from "../assets/images/logo.svg";
import TextField from "@mui/material/TextField";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


function Forgot_password() {
  const navigate = useNavigate()

  //usestate
  const [email, setemail] = useState('');


  const handleforgotpassword = async () => {
    try {
      const response = await axios.post("https://backend-epr.vibhotech.in/api/v1/users/forgotPassword", {
        email
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      console.log('Token sent to email successful:', response.data);
      // Navigate to the home page
      navigate("/login");
    } catch (error) {
      console.error('Token sent to email failed:', error.message);
      console.log('Error details:', error);
    }
  }


  return (
    <div>
      <section className="login">
        <div className="login_bg">
          <img src={login_bg} alt="" />
        </div>
        <Container>
          <div className="login_banner">
            <div className="login_banner_wrapper">
              <div className="login_banner_wrapper_content_main">
                <img src={logo} alt="" />
                <div className="login_banner_wrapper_content">
                  <h2>Welcome back! </h2>
                  <p>Login to access all your data</p>
                </div>
                <div className="login_form">
                  <TextField
                    id="outlined-basic"
                    label="Email ID"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                  <div className="login_button">
                    <Button onClick={() => { handleforgotpassword() }} disableRipple disableElevation>Login</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
}

export default Forgot_password;
