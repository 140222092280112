import React, { useState, useEffect } from "react";
import { Create } from "@mui/icons-material";
import { Box, Button, IconButton, TextField } from "@mui/material";
import "../Styles/pages/profile.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import Sidenav from "../layouts/Sidenav";
import Topbar from "../layouts/Topbar";
import profile from '../assets/images/Profile.png'
const cl = "app-page-profile";

export const Profile =() => {
  const [profileData, setprofileData] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
const token=localStorage.getItem("token")
  async function fetchProfileData() {
    try {
      const response = await axios.get( `https://backend-epr.vibhotech.in/api/v1/users/me`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setprofileData(response.data.user);
     
      setName(profileData.name);
      setEmail(profileData.email);
     
      console.log(profileData);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  }

  useEffect(() => {
    fetchProfileData();
  }, []);

 

  return (
    <>
       <Box sx={{ display: "flex" }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          <Topbar />
      <div className={`${cl}`}>
        <div className={`${cl}__wrapper`}>
          <div className={`${cl}__card`}>
            <div className={`${cl}__image`}>
             <img src={profile}/>
            </div>

            <div className={`${cl}__info`}>
              <ul className={`${cl}__infolist`}>
                <li className={`${cl}__infolistitem`}>
                  <h6>Name</h6>
                  <p>{profileData.name}</p>
                </li>
                <li className={`${cl}__infolistitem`}>
                  <h6>Email</h6>
                  <p>{profileData.email}</p>
                </li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
      </Box>

</Box>
    </>
  );
}

export default Profile;
