import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Sidenav from "../layouts/Sidenav";
import Topbar from "../layouts/Topbar";
import "../Styles/pages/user_management.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import Pagination from '@mui/material/Pagination';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Substrate_type() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    clearFormFields(); // Clear form fields
  };
  const handleClose = () => {
    setOpen(false);
    clearFormFields(); 
    setEditingsubstrate("");
  };

  const clearFormFields = () => {
    setsubstrateName("");
    setsubstrateDescription("");
  }


  //get substrate data
  const [substrate, setsubstrate] = useState([]);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10); // Number of users to display per page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentsubstrate = substrate.slice(indexOfFirstUser, indexOfLastUser);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    getsubstrate()
  }, []);

  const getsubstrate = async () => {
    try {
      const response = await axios.get("https://backend-epr.vibhotech.in/api/v1/substrates");
      setsubstrate(response.data.substrates);
      console.log(response.data.substrates);
    }
    catch (error) {
      console.error('Error fetching substrates:', error.message);
    }
  }


  //add substrate
  const [editingsubstrate, setEditingsubstrate] = useState(null);
  const [substrateName, setsubstrateName] = useState();
  const [substrateDescription, setsubstrateDescription] = useState();

  const addsubstrate = async () => {
    //validation
    if (!substrateName || !substrateDescription) {
      toast.error('All fields are required. Please fill in all the fields.');
      return;
    }
    try {
      const response = await axios.post("https://backend-epr.vibhotech.in/api/v1/substrates", {
        substrateName,
        substrateDescription
      }, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      console.log(response.data.substrate);
      //clear input fileds
      setsubstrateName();
      setsubstrateDescription();
      //refresh the added substrates
      getsubstrate();
      handleClose();
    } catch (error) {
      console.error('Error adding substrate:', error.message);
      toast.error("Error adding substrate")
    }
  }


  // API call for updating substrates
  //edit function
  const handleEditClick = (substrates) => {
    setEditingsubstrate(substrates);
    console.log(substrates._id)
    // Pre-fill the input fields with the existing data
    setsubstrateName(substrates.substrateName || "");
    setsubstrateDescription(substrates.substrateDescription || "");
    //modal open
    setOpen(true);
  };

  const updatesubstrate = async () => {
    if (!substrateName || !substrateDescription) {
      toast.error('All fields are required. Please fill in all the fields.');
      return;
    }
    try {
      const response = await axios.patch(`https://backend-epr.vibhotech.in/api/v1/substrates/${editingsubstrate._id}`,
        {
          substrateName,
          substrateDescription
        }, {
        headers: {
          "Content-Type": "application/json",
        },
      }
      );
      console.log(response.data);
      // Refresh the substrate list after create
      getsubstrate()
      handleClose();
    } catch (error) {
      console.error("Error updating substrate:", error.message);
      toast.error("Error updating substrate")
    }
  }
  const [openDialog, setOpenDialog] = useState(false);
  const [substrateIdToDelete, setSubstrateIdToDelete] = useState(null);

  // Call your delete API or function here


  const handleDeleteClick = (substrateId) => {
    setSubstrateIdToDelete(substrateId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSubstrateIdToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(
        `https://backend-epr.vibhotech.in/api/v1/substrates/${substrateIdToDelete}`
      );
      // Refresh the substrate list after deletion
      getsubstrate();
      console.log(`Substrate with ID ${substrateIdToDelete} deleted successfully.`);
    }  catch (error) {
      console.error(`Error deleting substrate with ID ${substrateIdToDelete}:`, error.message);
      toast.error("Error deleting substrate")
    } finally {
      handleCloseDialog();
    }
  };

  return (
    <div>
      <React.Fragment>
        <BootstrapDialog className="sales_entry"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
           {editingsubstrate ? "Update Substrate" : "Add New Substrate"} 
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <div className="adduser_bg">
              <div className="user_form">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Substrate Name"
                      variant="outlined"
                      value={substrateName}
                      onChange={(e) => setsubstrateName(e.target.value)}

                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Substrate Description"
                      variant="outlined"
                      value={substrateDescription}
                      onChange={(e) => setsubstrateDescription(e.target.value)}

                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="cancel_save_user">
              <Button disableRipple disableElevation className="cancel" onClick={handleClose} >Cancel</Button>
              <Button disableRipple disableElevation className='save_user' onClick={editingsubstrate ? updatesubstrate : addsubstrate}>{editingsubstrate ? "Update substrate" : "Save substrate"}</Button>
            </div>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
      <React.Fragment>
      <BootstrapDialog
        className="sales_delete"
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <DialogContent>
          <div className="adduser_bg">
            <div className="user_form">
              <div className="delete_sales_list">
                <h3>Delete Substrates List</h3>
                <p>
                  Are you sure you want to delete the Substrates record from Substrates
                  list?
                </p>
                <div className="cancel_save_user">
                  <Button
                    disableRipple
                    disableElevation
                    className="cancel"
                    onClick={handleCloseDialog}
                  >
                    Cancel
                  </Button>
                  <Button
                    disableRipple
                    disableElevation
                    className="save_user"
                    onClick={handleConfirmDelete}
                  >
                    Yes, Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>

    
    </React.Fragment>
      <Box sx={{ display: "flex" }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          <Topbar />
          <section className="main_box">
            <div className="table_box">
              <TableContainer component={Paper}>
                <div className="table_inbox">
                  <div className="add_user_list">
                    <div className="add_user_content">
                      <h3>Substrate Type</h3>
                    </div>
                    <div className="add_user">
                      <Button
                        variant="outlined"
                        disableRipple
                        disableElevation
                        onClick={handleClickOpen}
                      >
                        <span className="icon">
                          <AddRoundedIcon />
                        </span>
                        <span className="content">Add Substrate</span>
                      </Button>
                    </div>
                  </div>
                  <Table
                    className="adduser_table user_table"
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow className="table_head">
                        <TableCell>Sl.No</TableCell>
                        <TableCell align="left">Substrate ID</TableCell>
                        <TableCell align="left">Substrate Name</TableCell>
                        <TableCell align="left">Substrate Description</TableCell>
                        <TableCell align="center">Manage</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table_body">
                      {currentsubstrate.map((substrates, index) => (
                        <TableRow key={substrates._id}>
                          <TableCell align="left">{(index + 1) + (currentPage - 1) * usersPerPage}</TableCell>
                          <TableCell align="left">{substrates._id}</TableCell>
                          <TableCell align="left">{substrates.substrateName}</TableCell>
                          <TableCell align="left">{substrates.substrateDescription}</TableCell>
                          <TableCell className="edit_delete" align="right">
                           
                            <Button
                              disableRipple
                              disableElevation
                              onClick={() => handleDeleteClick(substrates._id)}
                              className="edit_button delete_button"
                            >
                              <DeleteIcon />
                            </Button>
                            <Button
                              disableRipple
                              disableElevation
                              className="edit_button"
                              onClick={() => handleEditClick(substrates)}>
                              <BorderColorRoundedIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div className="pagination_table">
                    <Pagination
                      count={Math.ceil(substrate.length / usersPerPage)}
                      page={currentPage}
                      onChange={(event, page) => paginate(page)} />
                  </div>
                </div>
              </TableContainer>
            </div>
          </section>
        </Box>
      </Box>
    </div>
  );
}

export default Substrate_type;
